import stringRandom from 'string-random'
import report, { IExtra, ReportEvent, ReportType } from './report'

export declare interface VisitReportEvent extends ReportEvent {
  from: string
  vid: string
  sequence: number
  duration: number
  event: string
}

const KEY_VISIT = 'GRAB_VISIT'
interface CacheVisit {
  vid: string
  sequence: number
  from: string
  timestamp: number
}
let cacheVisit = {} as CacheVisit

export default function () {
  loadStore()
  reportVisitEvent()
  listenNextVisit()
}

function loadStore() {
  const now = new Date().getTime()
  const storeVisit = sessionStorage.getItem(KEY_VISIT)
  let isNewVisit = true
  if (storeVisit) {
    cacheVisit = JSON.parse(storeVisit) as CacheVisit
    if (now - cacheVisit.timestamp > 3600 * 1000) {
      // 大于一个小时，认为是开启一次新的访问
      isNewVisit = true
    } else {
      isNewVisit = false
    }
  }
  if (isNewVisit) {
    cacheVisit = {
      vid: stringRandom(16),
      sequence: 0,
      from: document.referrer,
      timestamp: new Date().getTime(),
    }
  }
}

function listenNextVisit() {
  // 监听 history.pushState
  const pushState = history.pushState
  history.pushState = function (data: any, title: string, url?: string | null) {
    try {
      return pushState.apply(history, [data, title, url])
    } finally {
      reportVisitEvent()
    }
  }
}

function reportVisitEvent() {
  const extra: IExtra = {}
  let event = 'visit' + location.pathname.replace(/\//g, '_')
  if (event === 'visit_') event = 'visit_home'
  const now = new Date().getTime()
  const reportEvent: VisitReportEvent = {
    type: ReportType.Visit,
    from: cacheVisit.from,
    vid: cacheVisit.vid,
    sequence: cacheVisit.sequence,
    duration: now - cacheVisit.timestamp,
    event,
    extra,
  }
  report(reportEvent)

  cacheVisit.from = location.href
  cacheVisit.sequence = cacheVisit.sequence + 1
  cacheVisit.timestamp = now
  sessionStorage.setItem(KEY_VISIT, JSON.stringify(cacheVisit))
}
