import report, { ReportType, ReportEvent } from './report'

export declare interface LoadReportEvent extends ReportEvent {
  fp: number
  fcp: number
}

let hasReport = false

export default function load() {
  window.addEventListener('load', reportLoadEvent)
  setTimeout(reportLoadEvent, 3000)
}

function reportLoadEvent() {
  if (hasReport) return
  hasReport = true
  const paintEntries = performance.getEntriesByType('paint')
  const loadEvent: LoadReportEvent = {
    type: ReportType.Load,
    fp: parseInt('' + (paintEntries?.[0]?.startTime || 0)),
    fcp: parseInt('' + (paintEntries?.[1]?.startTime || 0)),
  }
  report(loadEvent)
}
