export const defaultTheme = {
  primary: '#307DFF',
  primaryHover: '#3985FF',
  primaryActive: '#0051cd',
}
export const blueTheme = {
  primary: '#2C6BFF',
  primaryHover: '#3985FF',
  primaryActive: '#0051cd',
}
export const blueThemeName = [
  'push',
  'identify',
  'sms',
  'analytics',
  'share',
  'im',
  'ums',
  'vaas',
  'mlink',
  'intelligent-operation',
  'marketing',
  'mssp',
  'overseas-messaging-cloud',
]
export const greenThemeName = ['ssp', 'dsp', 'fintech', 'iAudience']

export const hasNavName = [
  ...blueThemeName,
  ...greenThemeName,
  'about',
  'financial-industry',
  'message-cloud',
  'maketing-cloud',
  'safety-certificate',
  'retail',
  'education',
  'media',
  'case',
]
