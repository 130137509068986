import { DocsMenu, DocsMenuRes } from '~/api/docs'

export interface DirectoryNode {
  level: number
  content: string
  id: string
  text?: string
  children: DirectoryNode[]
}

export function searchMenu(menus: DocsMenu[], searchPath: string): DocsMenu[] {
  if (searchPath.includes('/docs/')) {
    const index = searchPath.indexOf('/docs/')
    searchPath = searchPath.substring(index + '/docs'.length)
  }
  searchPath = decodeURIComponent(searchPath)
  // console.log('searchMenu', JSON.parse(JSON.stringify(menus)), searchPath)
  return loopSearch(menus, searchPath)
}

function loopSearch(menus: DocsMenu[], searchPath: string): DocsMenu[] {
  if (!menus || menus.length === 0) return []
  for (let i = 0; i < menus.length; i++) {
    const menu = menus[i]
    const path = menu.customPath ? menu.customPath : menu.path
    if (trimSlash(path) === trimSlash(searchPath)) return [menu]
    const childMenu = loopSearch(menu.children, searchPath)
    if (childMenu.length > 0) return [...childMenu, menu]
  }
  return []
}

function trimSlash(path?: string) {
  if (!path) return path
  return path.replace(/^\/(.*)$/, '$1').replace(/^(.*)\/$/, '$1')
}

export const toPath = (menu: DocsMenu | string) => {
  if (typeof menu === 'string') {
    if (!menu) return '/docs'
    return '/docs' + fixPath(menu)
  } else {
    if (menu.path) return '/docs' + fixPath(menu.path)
    return '/docs/toPath'
  }
}

function fixPath(p: string) {
  p = p.endsWith('.md') ? p.substring(0, p.length - 3) : p
  p = p.startsWith('/') ? p : `/${p}`
  return p
}

// 根据文章内容获取目录列表
export const getDirectory = (htmlStr: string) => {
  const titleRegExp = new RegExp('<h[2,3]+(([\\s\\S])*?)<\\/h[2,3]+>', 'gi')
  const idRegExp = new RegExp('<h[2,3,4,5]+.*?id="(.*?)"')
  const hTextExp = new RegExp('<h[2,3,4,5]([^>]*)>([^<]*)<')
  const hTextExp2 = new RegExp('<h[2,3,4,5]([^>]*)>(<span([^<]*)>)?([^<]*)<')
  const dataIdRegExp = new RegExp('<h[2,3,4,5]+.*?data-id="(.*?)"')
  const titleList = htmlStr.match(titleRegExp)
  const list: DirectoryNode[] = []
  if (titleList && titleList.length) {
    for (let i = 0; i < titleList.length; i++) {
      const level = Number(titleList[i].match(/\d/)![0])
      const contentMatchRes = titleList[i].match(idRegExp)
      const dataIdMatchRes = titleList[i].match(dataIdRegExp)
      if (contentMatchRes) {
        const content = contentMatchRes[1]
        const matchArr = titleList[i].match(hTextExp)
        const text = (matchArr && (matchArr[2] || matchArr[4])) || ''
        list.push({
          level,
          content,
          text: text || content,
          children: [],
          id: dataIdMatchRes ? dataIdMatchRes[1] : '',
        })
      }
    }
  }
  return list
}

export function findFirstContentMenu(docsMenus: DocsMenu[]): DocsMenu | null {
  if (!docsMenus || docsMenus.length === 0) return null
  for (let i = 0; i < docsMenus.length; i++) {
    const docsMenu = docsMenus[i]
    if (docsMenu.path) return docsMenu
    const foundMenu = findFirstContentMenu(docsMenu.children)
    if (foundMenu) return foundMenu
  }
  return null
}

// 为适配老内容做别名适配
export const setAlias = (htmlStr: string) => {
  function getEn(str: string) {
    const lowStr = str.toLowerCase()
    let newStr = ''
    for (let i = 0; i < lowStr.length; i++) {
      const code = lowStr.charCodeAt(i)
      if (code > 96 && code < 123) {
        newStr += lowStr.charAt(i)
      }
    }
    return newStr || '_'
  }
  const titleRegExp = new RegExp('<h[1,2,3,4,5]+(([\\s\\S])*?)<\\/h[1,2,3,4,5]+>', 'gi')
  const idRegExp = new RegExp('<h[1,2,3,4,5]+.*?id="(.*?)"')
  const titleList = htmlStr.match(titleRegExp)
  const list: { [keys: string]: string } = {}
  if (titleList && titleList.length) {
    const tempObj: any = { _: 1 }
    for (let i = 0; i < titleList.length; i++) {
      const contentMatchRes = titleList[i].match(idRegExp)
      if (contentMatchRes) {
        const content = contentMatchRes[1]
        let newId = getEn(content)
        if (newId === '_') {
          newId = '_' + tempObj._
          tempObj._ += 1
        } else if (tempObj[newId]) {
          const temp = newId
          newId += '_' + tempObj[newId]
          tempObj[temp] += 1
        } else {
          tempObj[newId] = 1
        }

        list[newId] = content
      }
    }
  }
  return list
}

// 目录结构转成树状结构
export const directoryToTree = (arr: DirectoryNode[]) => {
  if (!arr || arr.length === 0) return []
  const vnode: DirectoryNode = { level: 0, content: '', id: '', children: [] }
  const stack = [vnode]
  arr.forEach((item) => {
    let pre = stack[stack.length - 1]
    while (item.level < pre.level) {
      stack.pop()
      pre = stack[stack.length - 1]
    }
    if (item.level > pre.level) {
      if (!pre.children) pre.children = []
      pre.children.push(item)
      stack.push(item)
    } else if (item.level === pre.level) {
      stack[stack.length - 2].children.push(item)
      stack[stack.length - 1] = item
    }
  })
  return vnode.children
}

// 复制文本
export const copyStr = (str: string, isCode = false) => {
  const copyText = (e: any) => {
    e.preventDefault()
    if (isCode) {
      str = str
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'")
    }
    if (e.clipboardData) {
      e.clipboardData.setData('text/plain', str)
    } else if ((window as any).clipboardData) {
      ;(window as any).clipboardData.setData('Text', str)
    }
  }
  window.addEventListener('copy', copyText)
  document.execCommand('copy')
  window.removeEventListener('copy', copyText)
}

export const setIframeClass = () => {
  if (window.top !== window) {
    document.body.classList.add('iframe')
  } else {
    document.body.classList.remove('iframe')
  }
}

// 获取children菜单,组装成markdown文档
export const getCustomMd = (docsDataRes: DocsMenuRes, docsPath: string, locale: string) => {
  let customMd = ''
  let menuMatch = {} as DocsMenu
  const docsMenu = docsDataRes[locale]
  const getCustomTarget = (docsData: DocsMenu[] = [], docsPath: string) => {
    for (let i = 0; i < docsData.length; i++) {
      const element = docsData[i]
      const path = element.customPath ? element.customPath : element.path
      if (path && path === docsPath) {
        menuMatch = element
        break
      }
      if (element.children && element.children.length) {
        getCustomTarget(element.children, docsPath)
      }
    }
  }
  getCustomTarget(docsMenu, docsPath)
  if (menuMatch.customPath && menuMatch.children && menuMatch.children.length) {
    menuMatch.children.forEach(
      (el) =>
        (customMd += `- [${decodeURIComponent(el.label)}](${locale === 'en_US' ? '' : '/' + locale}/docs${
          el.customPath ? el.customPath : el.path
        })\n`)
    )
  }
  return {
    customMd,
    menuMatch,
  }
}

export const addCustomPath = (docsMenuRes: DocsMenuRes, path = '') => {
  const loopAddCustomPath = (docsMenu: DocsMenu[], path = '') => {
    docsMenu.forEach((item) => {
      if (item.children && item.children.length) {
        if (item.path === undefined) {
          item.customPath = path + `/${item.name}`
        }
        loopAddCustomPath(item.children, item.customPath)
      }
    })
  }
  Object.values(docsMenuRes).forEach((element) => {
    loopAddCustomPath(element)
  })
}

export const getSuffixRoutePath = (routePath: string) => {
  const index = routePath.indexOf('/docs')
  if (index > -1) {
    const docsPath = routePath.substring(index + '/docs'.length)
    return docsPath
  }
  return routePath
}
