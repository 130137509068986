import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5c56e6d9 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _66bdb6cd = () => interopDefault(import('../pages/accounts/index.vue' /* webpackChunkName: "pages/accounts/index" */))
const _10cec72a = () => interopDefault(import('../pages/app-push.vue' /* webpackChunkName: "pages/app-push" */))
const _7d273589 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _b6952e28 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _aa5f063c = () => interopDefault(import('../pages/docs/index.vue' /* webpackChunkName: "pages/docs/index" */))
const _2a936f68 = () => interopDefault(import('../pages/email.vue' /* webpackChunkName: "pages/email" */))
const _7c0ec19e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3b8ef950 = () => interopDefault(import('../pages/license/index.vue' /* webpackChunkName: "pages/license/index" */))
const _03fdeefe = () => interopDefault(import('../pages/push.vue' /* webpackChunkName: "pages/push" */))
const _7ac010b6 = () => interopDefault(import('../pages/sms.vue' /* webpackChunkName: "pages/sms" */))
const _2c6c6e27 = () => interopDefault(import('../pages/ums.vue' /* webpackChunkName: "pages/ums" */))
const _0f89c152 = () => interopDefault(import('../pages/web-push.vue' /* webpackChunkName: "pages/web-push" */))
const _d8104698 = () => interopDefault(import('../pages/whatsapp-business-api.vue' /* webpackChunkName: "pages/whatsapp-business-api" */))
const _20ccf93f = () => interopDefault(import('../pages/accounts/add-user-info.vue' /* webpackChunkName: "pages/accounts/add-user-info" */))
const _7a069430 = () => interopDefault(import('../pages/accounts/check-captcha.vue' /* webpackChunkName: "pages/accounts/check-captcha" */))
const _e0c27068 = () => interopDefault(import('../pages/accounts/countrys-info.ts' /* webpackChunkName: "pages/accounts/countrys-info" */))
const _09068e1c = () => interopDefault(import('../pages/accounts/forget-pwd.vue' /* webpackChunkName: "pages/accounts/forget-pwd" */))
const _e30ba5c4 = () => interopDefault(import('../pages/accounts/invite.vue' /* webpackChunkName: "pages/accounts/invite" */))
const _13c446d2 = () => interopDefault(import('../pages/accounts/need2fa.vue' /* webpackChunkName: "pages/accounts/need2fa" */))
const _4ce19778 = () => interopDefault(import('../pages/accounts/organize.vue' /* webpackChunkName: "pages/accounts/organize" */))
const _3899e534 = () => interopDefault(import('../pages/accounts/pwd-updated.vue' /* webpackChunkName: "pages/accounts/pwd-updated" */))
const _97cf288c = () => interopDefault(import('../pages/accounts/reset-pwd.vue' /* webpackChunkName: "pages/accounts/reset-pwd" */))
const _14097937 = () => interopDefault(import('../pages/accounts/signin.vue' /* webpackChunkName: "pages/accounts/signin" */))
const _ef49b914 = () => interopDefault(import('../pages/accounts/signinTool.ts' /* webpackChunkName: "pages/accounts/signinTool" */))
const _289fcfad = () => interopDefault(import('../pages/accounts/signup.vue' /* webpackChunkName: "pages/accounts/signup" */))
const _c759088a = () => interopDefault(import('../pages/accounts/third-party.vue' /* webpackChunkName: "pages/accounts/third-party" */))
const _696c0a26 = () => interopDefault(import('../pages/accounts/turnstile.ts' /* webpackChunkName: "pages/accounts/turnstile" */))
const _479af0f4 = () => interopDefault(import('../pages/license/privacy.vue' /* webpackChunkName: "pages/license/privacy" */))
const _e1326688 = () => interopDefault(import('../pages/license/service-policy.vue' /* webpackChunkName: "pages/license/service-policy" */))
const _228f7c53 = () => interopDefault(import('../pages/blog/page/_id.vue' /* webpackChunkName: "pages/blog/page/_id" */))
const _d4cce02c = () => interopDefault(import('../pages/blog/preview/_id.vue' /* webpackChunkName: "pages/blog/preview/_id" */))
const _6ae70631 = () => interopDefault(import('../pages/blog/_id.vue' /* webpackChunkName: "pages/blog/_id" */))
const _51567c22 = () => interopDefault(import('../pages/docs/_.vue' /* webpackChunkName: "pages/docs/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _5c56e6d9,
    name: "about___en_US"
  }, {
    path: "/accounts",
    component: _66bdb6cd,
    name: "accounts___en_US"
  }, {
    path: "/app-push",
    component: _10cec72a,
    name: "app-push___en_US"
  }, {
    path: "/blog",
    component: _7d273589,
    name: "blog___en_US"
  }, {
    path: "/contact",
    component: _b6952e28,
    name: "contact___en_US"
  }, {
    path: "/docs",
    component: _aa5f063c,
    name: "docs___en_US"
  }, {
    path: "/email",
    component: _2a936f68,
    name: "email___en_US"
  }, {
    path: "/ja_JP",
    component: _7c0ec19e,
    name: "index___ja_JP"
  }, {
    path: "/license",
    component: _3b8ef950,
    name: "license___en_US"
  }, {
    path: "/push",
    component: _03fdeefe,
    name: "push___en_US"
  }, {
    path: "/sms",
    component: _7ac010b6,
    name: "sms___en_US"
  }, {
    path: "/th_TH",
    component: _7c0ec19e,
    name: "index___th_TH"
  }, {
    path: "/ums",
    component: _2c6c6e27,
    name: "ums___en_US"
  }, {
    path: "/web-push",
    component: _0f89c152,
    name: "web-push___en_US"
  }, {
    path: "/whatsapp-business-api",
    component: _d8104698,
    name: "whatsapp-business-api___en_US"
  }, {
    path: "/zh_CN",
    component: _7c0ec19e,
    name: "index___zh_CN"
  }, {
    path: "/zh_TW",
    component: _7c0ec19e,
    name: "index___zh_TW"
  }, {
    path: "/accounts/add-user-info",
    component: _20ccf93f,
    name: "accounts-add-user-info___en_US"
  }, {
    path: "/accounts/check-captcha",
    component: _7a069430,
    name: "accounts-check-captcha___en_US"
  }, {
    path: "/accounts/countrys-info",
    component: _e0c27068,
    name: "accounts-countrys-info___en_US"
  }, {
    path: "/accounts/forget-pwd",
    component: _09068e1c,
    name: "accounts-forget-pwd___en_US"
  }, {
    path: "/accounts/invite",
    component: _e30ba5c4,
    name: "accounts-invite___en_US"
  }, {
    path: "/accounts/need2fa",
    component: _13c446d2,
    name: "accounts-need2fa___en_US"
  }, {
    path: "/accounts/organize",
    component: _4ce19778,
    name: "accounts-organize___en_US"
  }, {
    path: "/accounts/pwd-updated",
    component: _3899e534,
    name: "accounts-pwd-updated___en_US"
  }, {
    path: "/accounts/reset-pwd",
    component: _97cf288c,
    name: "accounts-reset-pwd___en_US"
  }, {
    path: "/accounts/signin",
    component: _14097937,
    name: "accounts-signin___en_US"
  }, {
    path: "/accounts/signinTool",
    component: _ef49b914,
    name: "accounts-signinTool___en_US"
  }, {
    path: "/accounts/signup",
    component: _289fcfad,
    name: "accounts-signup___en_US"
  }, {
    path: "/accounts/third-party",
    component: _c759088a,
    name: "accounts-third-party___en_US"
  }, {
    path: "/accounts/turnstile",
    component: _696c0a26,
    name: "accounts-turnstile___en_US"
  }, {
    path: "/ja_JP/about",
    component: _5c56e6d9,
    name: "about___ja_JP"
  }, {
    path: "/ja_JP/accounts",
    component: _66bdb6cd,
    name: "accounts___ja_JP"
  }, {
    path: "/ja_JP/app-push",
    component: _10cec72a,
    name: "app-push___ja_JP"
  }, {
    path: "/ja_JP/blog",
    component: _7d273589,
    name: "blog___ja_JP"
  }, {
    path: "/ja_JP/contact",
    component: _b6952e28,
    name: "contact___ja_JP"
  }, {
    path: "/ja_JP/docs",
    component: _aa5f063c,
    name: "docs___ja_JP"
  }, {
    path: "/ja_JP/email",
    component: _2a936f68,
    name: "email___ja_JP"
  }, {
    path: "/ja_JP/license",
    component: _3b8ef950,
    name: "license___ja_JP"
  }, {
    path: "/ja_JP/push",
    component: _03fdeefe,
    name: "push___ja_JP"
  }, {
    path: "/ja_JP/sms",
    component: _7ac010b6,
    name: "sms___ja_JP"
  }, {
    path: "/ja_JP/ums",
    component: _2c6c6e27,
    name: "ums___ja_JP"
  }, {
    path: "/ja_JP/web-push",
    component: _0f89c152,
    name: "web-push___ja_JP"
  }, {
    path: "/ja_JP/whatsapp-business-api",
    component: _d8104698,
    name: "whatsapp-business-api___ja_JP"
  }, {
    path: "/license/privacy",
    component: _479af0f4,
    name: "license-privacy___en_US"
  }, {
    path: "/license/service-policy",
    component: _e1326688,
    name: "license-service-policy___en_US"
  }, {
    path: "/th_TH/about",
    component: _5c56e6d9,
    name: "about___th_TH"
  }, {
    path: "/th_TH/accounts",
    component: _66bdb6cd,
    name: "accounts___th_TH"
  }, {
    path: "/th_TH/app-push",
    component: _10cec72a,
    name: "app-push___th_TH"
  }, {
    path: "/th_TH/blog",
    component: _7d273589,
    name: "blog___th_TH"
  }, {
    path: "/th_TH/contact",
    component: _b6952e28,
    name: "contact___th_TH"
  }, {
    path: "/th_TH/docs",
    component: _aa5f063c,
    name: "docs___th_TH"
  }, {
    path: "/th_TH/email",
    component: _2a936f68,
    name: "email___th_TH"
  }, {
    path: "/th_TH/license",
    component: _3b8ef950,
    name: "license___th_TH"
  }, {
    path: "/th_TH/push",
    component: _03fdeefe,
    name: "push___th_TH"
  }, {
    path: "/th_TH/sms",
    component: _7ac010b6,
    name: "sms___th_TH"
  }, {
    path: "/th_TH/ums",
    component: _2c6c6e27,
    name: "ums___th_TH"
  }, {
    path: "/th_TH/web-push",
    component: _0f89c152,
    name: "web-push___th_TH"
  }, {
    path: "/th_TH/whatsapp-business-api",
    component: _d8104698,
    name: "whatsapp-business-api___th_TH"
  }, {
    path: "/zh_CN/about",
    component: _5c56e6d9,
    name: "about___zh_CN"
  }, {
    path: "/zh_CN/accounts",
    component: _66bdb6cd,
    name: "accounts___zh_CN"
  }, {
    path: "/zh_CN/app-push",
    component: _10cec72a,
    name: "app-push___zh_CN"
  }, {
    path: "/zh_CN/blog",
    component: _7d273589,
    name: "blog___zh_CN"
  }, {
    path: "/zh_CN/contact",
    component: _b6952e28,
    name: "contact___zh_CN"
  }, {
    path: "/zh_CN/docs",
    component: _aa5f063c,
    name: "docs___zh_CN"
  }, {
    path: "/zh_CN/email",
    component: _2a936f68,
    name: "email___zh_CN"
  }, {
    path: "/zh_CN/license",
    component: _3b8ef950,
    name: "license___zh_CN"
  }, {
    path: "/zh_CN/push",
    component: _03fdeefe,
    name: "push___zh_CN"
  }, {
    path: "/zh_CN/sms",
    component: _7ac010b6,
    name: "sms___zh_CN"
  }, {
    path: "/zh_CN/ums",
    component: _2c6c6e27,
    name: "ums___zh_CN"
  }, {
    path: "/zh_CN/web-push",
    component: _0f89c152,
    name: "web-push___zh_CN"
  }, {
    path: "/zh_CN/whatsapp-business-api",
    component: _d8104698,
    name: "whatsapp-business-api___zh_CN"
  }, {
    path: "/zh_TW/about",
    component: _5c56e6d9,
    name: "about___zh_TW"
  }, {
    path: "/zh_TW/accounts",
    component: _66bdb6cd,
    name: "accounts___zh_TW"
  }, {
    path: "/zh_TW/app-push",
    component: _10cec72a,
    name: "app-push___zh_TW"
  }, {
    path: "/zh_TW/blog",
    component: _7d273589,
    name: "blog___zh_TW"
  }, {
    path: "/zh_TW/contact",
    component: _b6952e28,
    name: "contact___zh_TW"
  }, {
    path: "/zh_TW/docs",
    component: _aa5f063c,
    name: "docs___zh_TW"
  }, {
    path: "/zh_TW/email",
    component: _2a936f68,
    name: "email___zh_TW"
  }, {
    path: "/zh_TW/license",
    component: _3b8ef950,
    name: "license___zh_TW"
  }, {
    path: "/zh_TW/push",
    component: _03fdeefe,
    name: "push___zh_TW"
  }, {
    path: "/zh_TW/sms",
    component: _7ac010b6,
    name: "sms___zh_TW"
  }, {
    path: "/zh_TW/ums",
    component: _2c6c6e27,
    name: "ums___zh_TW"
  }, {
    path: "/zh_TW/web-push",
    component: _0f89c152,
    name: "web-push___zh_TW"
  }, {
    path: "/zh_TW/whatsapp-business-api",
    component: _d8104698,
    name: "whatsapp-business-api___zh_TW"
  }, {
    path: "/ja_JP/accounts/add-user-info",
    component: _20ccf93f,
    name: "accounts-add-user-info___ja_JP"
  }, {
    path: "/ja_JP/accounts/check-captcha",
    component: _7a069430,
    name: "accounts-check-captcha___ja_JP"
  }, {
    path: "/ja_JP/accounts/countrys-info",
    component: _e0c27068,
    name: "accounts-countrys-info___ja_JP"
  }, {
    path: "/ja_JP/accounts/forget-pwd",
    component: _09068e1c,
    name: "accounts-forget-pwd___ja_JP"
  }, {
    path: "/ja_JP/accounts/invite",
    component: _e30ba5c4,
    name: "accounts-invite___ja_JP"
  }, {
    path: "/ja_JP/accounts/need2fa",
    component: _13c446d2,
    name: "accounts-need2fa___ja_JP"
  }, {
    path: "/ja_JP/accounts/organize",
    component: _4ce19778,
    name: "accounts-organize___ja_JP"
  }, {
    path: "/ja_JP/accounts/pwd-updated",
    component: _3899e534,
    name: "accounts-pwd-updated___ja_JP"
  }, {
    path: "/ja_JP/accounts/reset-pwd",
    component: _97cf288c,
    name: "accounts-reset-pwd___ja_JP"
  }, {
    path: "/ja_JP/accounts/signin",
    component: _14097937,
    name: "accounts-signin___ja_JP"
  }, {
    path: "/ja_JP/accounts/signinTool",
    component: _ef49b914,
    name: "accounts-signinTool___ja_JP"
  }, {
    path: "/ja_JP/accounts/signup",
    component: _289fcfad,
    name: "accounts-signup___ja_JP"
  }, {
    path: "/ja_JP/accounts/third-party",
    component: _c759088a,
    name: "accounts-third-party___ja_JP"
  }, {
    path: "/ja_JP/accounts/turnstile",
    component: _696c0a26,
    name: "accounts-turnstile___ja_JP"
  }, {
    path: "/ja_JP/license/privacy",
    component: _479af0f4,
    name: "license-privacy___ja_JP"
  }, {
    path: "/ja_JP/license/service-policy",
    component: _e1326688,
    name: "license-service-policy___ja_JP"
  }, {
    path: "/th_TH/accounts/add-user-info",
    component: _20ccf93f,
    name: "accounts-add-user-info___th_TH"
  }, {
    path: "/th_TH/accounts/check-captcha",
    component: _7a069430,
    name: "accounts-check-captcha___th_TH"
  }, {
    path: "/th_TH/accounts/countrys-info",
    component: _e0c27068,
    name: "accounts-countrys-info___th_TH"
  }, {
    path: "/th_TH/accounts/forget-pwd",
    component: _09068e1c,
    name: "accounts-forget-pwd___th_TH"
  }, {
    path: "/th_TH/accounts/invite",
    component: _e30ba5c4,
    name: "accounts-invite___th_TH"
  }, {
    path: "/th_TH/accounts/need2fa",
    component: _13c446d2,
    name: "accounts-need2fa___th_TH"
  }, {
    path: "/th_TH/accounts/organize",
    component: _4ce19778,
    name: "accounts-organize___th_TH"
  }, {
    path: "/th_TH/accounts/pwd-updated",
    component: _3899e534,
    name: "accounts-pwd-updated___th_TH"
  }, {
    path: "/th_TH/accounts/reset-pwd",
    component: _97cf288c,
    name: "accounts-reset-pwd___th_TH"
  }, {
    path: "/th_TH/accounts/signin",
    component: _14097937,
    name: "accounts-signin___th_TH"
  }, {
    path: "/th_TH/accounts/signinTool",
    component: _ef49b914,
    name: "accounts-signinTool___th_TH"
  }, {
    path: "/th_TH/accounts/signup",
    component: _289fcfad,
    name: "accounts-signup___th_TH"
  }, {
    path: "/th_TH/accounts/third-party",
    component: _c759088a,
    name: "accounts-third-party___th_TH"
  }, {
    path: "/th_TH/accounts/turnstile",
    component: _696c0a26,
    name: "accounts-turnstile___th_TH"
  }, {
    path: "/th_TH/license/privacy",
    component: _479af0f4,
    name: "license-privacy___th_TH"
  }, {
    path: "/th_TH/license/service-policy",
    component: _e1326688,
    name: "license-service-policy___th_TH"
  }, {
    path: "/zh_CN/accounts/add-user-info",
    component: _20ccf93f,
    name: "accounts-add-user-info___zh_CN"
  }, {
    path: "/zh_CN/accounts/check-captcha",
    component: _7a069430,
    name: "accounts-check-captcha___zh_CN"
  }, {
    path: "/zh_CN/accounts/countrys-info",
    component: _e0c27068,
    name: "accounts-countrys-info___zh_CN"
  }, {
    path: "/zh_CN/accounts/forget-pwd",
    component: _09068e1c,
    name: "accounts-forget-pwd___zh_CN"
  }, {
    path: "/zh_CN/accounts/invite",
    component: _e30ba5c4,
    name: "accounts-invite___zh_CN"
  }, {
    path: "/zh_CN/accounts/need2fa",
    component: _13c446d2,
    name: "accounts-need2fa___zh_CN"
  }, {
    path: "/zh_CN/accounts/organize",
    component: _4ce19778,
    name: "accounts-organize___zh_CN"
  }, {
    path: "/zh_CN/accounts/pwd-updated",
    component: _3899e534,
    name: "accounts-pwd-updated___zh_CN"
  }, {
    path: "/zh_CN/accounts/reset-pwd",
    component: _97cf288c,
    name: "accounts-reset-pwd___zh_CN"
  }, {
    path: "/zh_CN/accounts/signin",
    component: _14097937,
    name: "accounts-signin___zh_CN"
  }, {
    path: "/zh_CN/accounts/signinTool",
    component: _ef49b914,
    name: "accounts-signinTool___zh_CN"
  }, {
    path: "/zh_CN/accounts/signup",
    component: _289fcfad,
    name: "accounts-signup___zh_CN"
  }, {
    path: "/zh_CN/accounts/third-party",
    component: _c759088a,
    name: "accounts-third-party___zh_CN"
  }, {
    path: "/zh_CN/accounts/turnstile",
    component: _696c0a26,
    name: "accounts-turnstile___zh_CN"
  }, {
    path: "/zh_CN/license/privacy",
    component: _479af0f4,
    name: "license-privacy___zh_CN"
  }, {
    path: "/zh_CN/license/service-policy",
    component: _e1326688,
    name: "license-service-policy___zh_CN"
  }, {
    path: "/zh_TW/accounts/add-user-info",
    component: _20ccf93f,
    name: "accounts-add-user-info___zh_TW"
  }, {
    path: "/zh_TW/accounts/check-captcha",
    component: _7a069430,
    name: "accounts-check-captcha___zh_TW"
  }, {
    path: "/zh_TW/accounts/countrys-info",
    component: _e0c27068,
    name: "accounts-countrys-info___zh_TW"
  }, {
    path: "/zh_TW/accounts/forget-pwd",
    component: _09068e1c,
    name: "accounts-forget-pwd___zh_TW"
  }, {
    path: "/zh_TW/accounts/invite",
    component: _e30ba5c4,
    name: "accounts-invite___zh_TW"
  }, {
    path: "/zh_TW/accounts/need2fa",
    component: _13c446d2,
    name: "accounts-need2fa___zh_TW"
  }, {
    path: "/zh_TW/accounts/organize",
    component: _4ce19778,
    name: "accounts-organize___zh_TW"
  }, {
    path: "/zh_TW/accounts/pwd-updated",
    component: _3899e534,
    name: "accounts-pwd-updated___zh_TW"
  }, {
    path: "/zh_TW/accounts/reset-pwd",
    component: _97cf288c,
    name: "accounts-reset-pwd___zh_TW"
  }, {
    path: "/zh_TW/accounts/signin",
    component: _14097937,
    name: "accounts-signin___zh_TW"
  }, {
    path: "/zh_TW/accounts/signinTool",
    component: _ef49b914,
    name: "accounts-signinTool___zh_TW"
  }, {
    path: "/zh_TW/accounts/signup",
    component: _289fcfad,
    name: "accounts-signup___zh_TW"
  }, {
    path: "/zh_TW/accounts/third-party",
    component: _c759088a,
    name: "accounts-third-party___zh_TW"
  }, {
    path: "/zh_TW/accounts/turnstile",
    component: _696c0a26,
    name: "accounts-turnstile___zh_TW"
  }, {
    path: "/zh_TW/license/privacy",
    component: _479af0f4,
    name: "license-privacy___zh_TW"
  }, {
    path: "/zh_TW/license/service-policy",
    component: _e1326688,
    name: "license-service-policy___zh_TW"
  }, {
    path: "/ja_JP/blog/page/:id?",
    component: _228f7c53,
    name: "blog-page-id___ja_JP"
  }, {
    path: "/ja_JP/blog/preview/:id?",
    component: _d4cce02c,
    name: "blog-preview-id___ja_JP"
  }, {
    path: "/th_TH/blog/page/:id?",
    component: _228f7c53,
    name: "blog-page-id___th_TH"
  }, {
    path: "/th_TH/blog/preview/:id?",
    component: _d4cce02c,
    name: "blog-preview-id___th_TH"
  }, {
    path: "/zh_CN/blog/page/:id?",
    component: _228f7c53,
    name: "blog-page-id___zh_CN"
  }, {
    path: "/zh_CN/blog/preview/:id?",
    component: _d4cce02c,
    name: "blog-preview-id___zh_CN"
  }, {
    path: "/zh_TW/blog/page/:id?",
    component: _228f7c53,
    name: "blog-page-id___zh_TW"
  }, {
    path: "/zh_TW/blog/preview/:id?",
    component: _d4cce02c,
    name: "blog-preview-id___zh_TW"
  }, {
    path: "/blog/page/:id?",
    component: _228f7c53,
    name: "blog-page-id___en_US"
  }, {
    path: "/blog/preview/:id?",
    component: _d4cce02c,
    name: "blog-preview-id___en_US"
  }, {
    path: "/ja_JP/blog/:id",
    component: _6ae70631,
    name: "blog-id___ja_JP"
  }, {
    path: "/th_TH/blog/:id",
    component: _6ae70631,
    name: "blog-id___th_TH"
  }, {
    path: "/zh_CN/blog/:id",
    component: _6ae70631,
    name: "blog-id___zh_CN"
  }, {
    path: "/zh_TW/blog/:id",
    component: _6ae70631,
    name: "blog-id___zh_TW"
  }, {
    path: "/th_TH/docs/*",
    component: _51567c22,
    name: "docs-all___th_TH"
  }, {
    path: "/ja_JP/docs/*",
    component: _51567c22,
    name: "docs-all___ja_JP"
  }, {
    path: "/zh_CN/docs/*",
    component: _51567c22,
    name: "docs-all___zh_CN"
  }, {
    path: "/zh_TW/docs/*",
    component: _51567c22,
    name: "docs-all___zh_TW"
  }, {
    path: "/blog/:id",
    component: _6ae70631,
    name: "blog-id___en_US"
  }, {
    path: "/docs/*",
    component: _51567c22,
    name: "docs-all___en_US"
  }, {
    path: "/",
    component: _7c0ec19e,
    name: "index___en_US"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
