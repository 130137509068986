import { getDocsMD, getDocsMenu } from '@/api/docs'
import { getModule } from 'vuex-module-decorators'
import StoreModule from '@/store/store'
import { getCustomMd, addCustomPath, getSuffixRoutePath } from '@/utils/menu-helper'

export default async function ({ app, store, route, redirect }: any) {
  const Store = getModule(StoreModule, store)
  const local = app.i18n.locale
  const routePath = route.path.replace(`${local === 'en_US' ? '' : '/' + local}`, '')
  if (routePath === '/docs' || routePath === '/docs/') {
    redirect(`${local === 'en_US' ? '' : '/' + local}/docs/app-push/product-overview`)
    return
  }
  if (!Store.docsData.docsMenuRes) {
    const docsMenuRes = await getDocsMenu(app)
    if (!docsMenuRes) return
    addCustomPath(docsMenuRes)
    const suffixPath = getSuffixRoutePath(route.path)
    const fullPath = local + suffixPath
    const { customMd, menuMatch } = getCustomMd(docsMenuRes, suffixPath, local)
    let docsMD = ''
    if (customMd) {
      docsMD = customMd
    } else {
      docsMD = await getDocsMD(app, fullPath)
    }
    const docsData = {
      docsMenuRes,
      docsMD,
      isCustomMd: customMd !== '',
      menuMatch,
    }
    Store.setDocsData(docsData)
  }
}
