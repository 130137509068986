import { Context } from '@nuxt/types'
import ThemeStoreModule from '@/store/theme'
import { getModule } from 'vuex-module-decorators'
import { defaultTheme, blueTheme, blueThemeName, greenThemeName } from '../config/themeConfig'

const convertedPages = new Set<String>(blueThemeName)
const convertedPagesGreen = new Set<String>(greenThemeName)

export default function defineNuxtPlugin(context: Context) {
  if (!context.app.router) return
  const store = getModule(ThemeStoreModule, context.store)
  context.app.router.beforeEach((to, from, next) => {
    // console.log('from: ', from, ', to: ', to)
    const routeName = to.name?.split('__')[0]
    if (routeName && convertedPages.has(routeName)) {
      store.updateTheme(blueTheme)
      store.setIsTheme(true)
    } else if (routeName && convertedPagesGreen.has(routeName)) {
      store.updateTheme(defaultTheme)
      store.setIsTheme(true)
    } else {
      store.updateTheme(defaultTheme)
      store.setIsTheme(false)
    }
    next()
  })
}
