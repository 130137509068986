import Vue from 'vue'
import VueLayzeLoad from 'vue-lazyload'

Vue.use(VueLayzeLoad, {
  preLoad: 1,
  error: '',
  loading: '',
  attempt: 2,
  throttleWait: 200,
})
