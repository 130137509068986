import StoreModule from '@/store/store'
import { getModule } from 'vuex-module-decorators'
import { Context } from '@nuxt/types'
import { defineNuxtPlugin } from '@nuxtjs/composition-api'

const SEPARATE_WIDTH = 1024

export default defineNuxtPlugin((context: Context) => {
  const store = getModule(StoreModule, context.store)
  if (process.server) {
    const userAgent = context.req.headers['user-agent']
    if (userAgent && /Android|webOS|iPhone|iPod|BlackBerry|iPad/i.test(userAgent)) {
      store.setIsMobile(true)
    }
  }
  if (process.client) {
    const judgeDevice = () => {
      const { clientWidth } = document.body
      const isMobile = clientWidth <= SEPARATE_WIDTH
      if (store.isMobile !== isMobile) {
        store.setIsMobile(isMobile)
      }
    }
    window.addEventListener('resize', judgeDevice)
    judgeDevice()
  }
})
