import useClick from './click'
import useLoad from './load'
import useVisit from './visit'
export { track } from './custom'

import { Context } from '@nuxt/types'
import { defineNuxtPlugin } from '@nuxtjs/composition-api'

export default defineNuxtPlugin((context: Context) => {
  if (process.client) {
    useVisit()
    useLoad()
    useClick()
  }
})
