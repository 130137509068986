export interface footerItem {
  title?: string
  text: string
}

const meta = require('@/assets/img/logos/meta.png')
const aws = require('@/assets/img/logos/aws.png')
export const FOOTERCONFIG = {
  footerInfo: [
    {
      name: 'footer.contactUs.name',
      sub: [
        {
          title: 'footer.contactUs.sub[0]',
          href: 'mailto:sales@engagelab.com',
        },
        {
          title: 'footer.contactUs.sub[1]',
          sub: [
            {
              href: 'https://www.facebook.com/business/partner-directory/search?solution_type=messaging&ref=pd_home_hero_cta&id=5665772170149643&section=overview',
              icon: meta,
            },
            {
              href: 'https://partners.amazonaws.com/cn/partners/0018a00001kOa7dAAC/%E6%B7%B1%E5%9C%B3%E5%B8%82%E5%92%8C%E8%AE%AF%E5%8D%8E%E8%B0%B7%E4%BF%A1%E6%81%AF%E6%8A%80%E6%9C%AF%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8',
              icon: aws,
            },
          ],
        },
      ],
    },
    {
      name: 'footer.aboutUs.name',
      sub: [
        {
          title: 'footer.aboutUs.sub[0]',
          href: '/about',
        },
        {
          title: 'footer.aboutUs.sub[1]',
          href: '/blog',
        },
      ],
    },
    {
      name: 'footer.products.name',
      sub: [
        {
          title: 'footer.products.sub[0]',
          href: '/ums',
        },
        {
          title: 'footer.products.sub[1]',
          href: '/app-push',
        },
        {
          title: 'footer.products.sub[2]',
          href: '/web-push',
        },
        {
          title: 'footer.products.sub[3]',
          href: '/sms',
        },
        {
          title: 'footer.products.sub[4]',
          href: '/email',
        },
        {
          title: 'footer.products.sub[5]',
          href: '/whatsapp-business-api',
        },
      ],
    },
    {
      name: 'footer.support.name',
      sub: [
        {
          title: 'footer.support.sub[0]',
          href: '/contact',
        },
        {
          title: 'footer.support.sub[1]',
          href: '/license/privacy',
        },
        {
          title: 'footer.support.sub[2]',
          href: '/license/service-policy',
        },
      ],
    },
  ],
}
