import { NuxtAppOptions } from '@nuxt/types'
import config from '../utils/config'

export type DocsMenu = {
  name: string
  label: string
  path?: string
  updateTime?: string
  children: DocsMenu[]
  customPath?: string
}

export type DocsMenuRes = {
  [locale: string]: DocsMenu[]
}

export type DocsData = {
  docsMenuRes: DocsMenuRes
  docsMD: string
  isCustomMd?: boolean
  menuMatch?: DocsMenu
}

const baseURL = process.client ? '/docs-res' : config.docsBaseURL

export const getDocsHash = (app: NuxtAppOptions) => {
  return app.$axios
    .get<any, string>(baseURL + `/hash`, { responseType: 'text' })
    .then((res) => {
      return res.trim()
    })
    .catch((e) => {
      console.log('getDocsHash error')
      console.error(e)
      return ''
    })
}

export const getDocsMenu = (app: NuxtAppOptions) => {
  // console.log('getDocsMenu', config.cdnURL + `/docs/${hash}/menu.json`)
  return app.$axios
    .get<any, DocsMenuRes>(baseURL + `/menu.json`)
    .then((res) => {
      // console.log(baseURL + `/menu.json`, res, 'getDocsMenu')
      Object.assign(res, { ja_JP: res.en_US, th_TH: res.en_US })
      return res
    })
    .catch((e) => {
      console.log('getDocsMenu error', baseURL + `/menu.json`)
      console.error(e)
      return {
        en_US: [],
        zh_TW: [],
        zh_CN: [],
      } as DocsMenuRes
    })
}

export const getDocsMD = (app: NuxtAppOptions, path: string) => {
  // console.log('getDocsMD', config.cdnURL + `/docs/${hash}/${path}`)
  return app.$axios
    .get<any, string>(baseURL + `/${path}.md`, { responseType: 'text' })
    .then((res) => {
      // console.log('getDocsMD res', res)
      return res
    })
    .catch((e) => {
      console.log('getDocsMD error', path)
      console.error(e)
      return app.context.error({ statusCode: 404 })
    })
}
