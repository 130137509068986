const languageData = {
  zh_CN: {
    label: '中文',
    value: 'zh-cn',
  },
  zh_TW: {
    label: '繁体',
    value: 'zh-tw',
  },
  en_US: {
    label: '英语',
    value: 'en',
  },
  th_TH: {
    label: '泰语',
    value: 'th',
  },
  ja_JP: {
    label: '日语',
    value: 'ja',
  },
}

const qiyuData = {
  'zh-cn': {
    templateId: 6650910,
  },
  'zh-tw': {
    templateId: 6654046,
  },
  en: {
    templateId: 6651853,
  },
  ja: {
    templateId: 6651930,
  },
  th: {
    templateId: 6651931,
  },
}

export const openQiyu = (local: string) => {
  if (window.localStorage.getItem('isAcceptPrivacy') !== 'true') {
    return false
  }
  if (!window.ysf) return null
  const language = languageData[local].value
  let url = 'jiguang.cn'
  if (location.href.includes('engagelab')) url = 'engagelab.com'
  window.ysf('config', {
    language,
    title: `${url}(${language}) - ${document.title}`,
    success: () => {
      console.log('七鱼加载成功', language, qiyuData[language])
      window.ysf('open', {
        templateId: qiyuData[language].templateId,
      })
    },
    error: (error: any) => {
      console.error(error)
    },
  })
  return true
}
