const gtag = `window.dataLayer = window.dataLayer || []
function gtag() {
  window.dataLayer.push(arguments)
}
gtag('js', new Date())

gtag('config', 'G-8YNVRD4XR7')
`

const gtm = `;(function (w, d, s, l, i) {
  w[l] = w[l] || []
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
  const f = d.getElementsByTagName(s)[0]
  const j = d.createElement(s)
  const dl = l !== 'dataLayer' ? '&l=' + l : ''
  j.async = true
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
  f.parentNode.insertBefore(j, f)
})(window, document, 'script', 'dataLayer', 'GTM-TMRBBDF')`

const chat = `;(function (w, d, v3) {
  let language = 'en'
  document.cookie.split('; ').forEach((item) => {
    if (item.split('=')[0] === 'i18n_redirected') language = item.split('=')[1].split('_')[0]
  })
  w.chaportConfig = {
    appId: '63848ec2509a13227106e8ca',
    language: { use: language },
  }

  if (w.chaport) return
  v3 = w.chaport = {}
  v3._q = []
  v3._l = {}
  v3.q = function () {
    v3._q.push(arguments)
  }
  v3.on = function (e, fn) {
    if (!v3._l[e]) v3._l[e] = []
    v3._l[e].push(fn)
  }
  const s = d.createElement('script')
  s.type = 'text/javascript'
  s.async = true
  s.src = 'https://app.chaport.com/javascripts/insert.js'
  const ss = d.getElementsByTagName('script')[0]
  ss.parentNode.insertBefore(s, ss)
})(window, document)
`

const baidu = `
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?c57d6f00eefe084a4a6139a5564cfd3e";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();`

const qiyu = `
(function (w, d, n, a, j) {
  w[n] = w[n] || function () {
    (w[n].a = w[n].a || []).push(arguments);
  };
  j = d.createElement('script');
  j.async = true;
  j.src ='https://qiyukf.com/script/c66ee487fd34e670f315a8a5d1fdb0e8.js';
  d.body.appendChild(j);
})(window, document, 'ysf');
`

export const asyncScriptInit = () => {
  const scriptList = [gtag, gtm, qiyu, baidu]
  scriptList.forEach((item) => {
    const s = document.createElement('script')
    s.innerHTML = item
    document.head.appendChild(s)
  })
}
