import { Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { DocsData } from '@/api/docs'

export interface UserInfo {
  areaCode: string | null
  currentOrgId: string | null
  currentOrgName: string | null
  email: string
  firstName: string | null
  headImageUrl: string | null
  lastName: string | null
  locale: string | null
  passwordUpdateTime: number | null
  phone: string | null
  ssoRequestContent: string | null
  ssoRequestTitle: string | null
  isOrgOwner: boolean | null
}
@Module({
  name: 'store',
  stateFactory: true,
  namespaced: true,
})
export default class StoreModule extends VuexModule {
  isMobile = false
  userInfo = {} as UserInfo
  docsData = {} as DocsData

  get isPC() {
    return !this.isMobile
  }

  @Mutation
  setIsMobile(isMobile: boolean) {
    this.isMobile = isMobile
  }

  @Mutation
  setUserInfo(userInfo: UserInfo) {
    this.userInfo = userInfo
  }

  @Mutation
  setDocsData(data: DocsData) {
    this.docsData = data
  }
}
