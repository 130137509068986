import stringRandom from 'string-random'
import Cookie from 'cookie-universal'
import { postJson } from '@/utils/api'

export declare interface EventBase {
  url: string // 当前浏览器访问的地址
  did: string // 设备ID
  uid?: string // 用户ID
  p: string // 项目名称
}

/**
 * 附加字段类型
 */
export declare type IExtra = {
  [x: string]: string | number
}

/**
 * 定义上报自定义事件的方法类型
 */
export declare type ITrack = (event: string, extra?: IExtra | undefined | null) => void

export enum ReportType {
  Custom = 'custom',
  XHR = 'xhr',
  Load = 'load',
  Visit = 'visit',
  Error = 'error',
  Console = 'console',
  Click = 'click',
  Active = 'active',
}

export declare interface ReportEvent {
  type: ReportType
  base?: EventBase
  extra?: IExtra | null | undefined
}

export declare type IReport = (event: ReportEvent) => void

const cookies = Cookie()

const report: IReport = (data) => {
  data.base = buildBase()
  const url = `/api/console/website/report/${data.type}`
  postJson(url, data)
}

export default report

let cacheUid: string = ''
let cacheDid: string = ''
const KEY_DID = 'grab-did'

export const buildBase = (): EventBase => {
  if (!cacheDid) {
    cacheDid = localStorage?.getItem(KEY_DID) || ''
  }
  if (!cacheDid) {
    cacheDid = stringRandom(10)
    localStorage?.setItem(KEY_DID, cacheDid)
  }
  if (!cacheUid) {
    try {
      const etoken = cookies.get('Etoken')
      if (etoken) {
        cacheUid = JSON.parse(atob(etoken.split('.')[1])).accountId
      }
    } catch (e) {}
  }
  const url = window.location.href

  const eventBase: EventBase = {
    url,
    did: cacheDid,
    uid: cacheUid,
    p: 'oversea-nuxt-frontend',
  }
  return eventBase
}
