import { Context } from '@nuxt/types'
import { defineNuxtPlugin } from '@nuxtjs/composition-api'

export default defineNuxtPlugin((context: Context) => {
  if (process.client) {
    const firstVisitUrl = sessionStorage.getItem('first_visit_url')
    if (!firstVisitUrl) {
      const origin = new URLSearchParams(location.search).get('origin')
      sessionStorage.setItem('first_visit_url', origin ?? location.href)
    }
  }
})
