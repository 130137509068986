import report, { IExtra, ReportEvent, ReportType } from './report'
import { Elements } from './elements'
import { track } from './custom'

export declare interface ClickReportEvent extends ReportEvent {
  xpath: string
  domid: string
  domname: string
  event: string
  extra: IExtra
  x: number
  y: number
  href: string
}

export default function () {
  window.addEventListener(
    'click',
    (rawEvent: Event) => {
      const pointerEvent = rawEvent as PointerEvent
      const target: any = pointerEvent.target
      const text: string = target.innerText

      // 识别a标签的href跳转地址
      const hrefTarget = target.closest('a[href]')
      const href = hrefTarget ? hrefTarget.getAttribute('href') : ''

      // 识别event和extra
      let event = ''
      let extra: IExtra = {}
      const grabTarget = target.closest('[grab-event]')
      if (grabTarget) {
        event = grabTarget.getAttribute('grab-event')
        grabTarget.getAttributeNames().forEach((attrName: string) => {
          if (attrName === 'grab-extra') {
            const extraStr = grabTarget.getAttribute(attrName)
            if (extraStr) {
              try {
                const obj = JSON.parse(extraStr)
                extra = { ...extra, ...obj }
              } catch (e) {}
            }
          } else if (attrName.startsWith('grab-extra-')) {
            const key = attrName.substring('grab-extra-'.length)
            const value = grabTarget.getAttribute(attrName)
            extra[key] = value
          }
        })
      }

      const reportEvent: ClickReportEvent = {
        type: ReportType.Click,
        xpath: Elements.DOMPath.xPath(target, false),
        domid: target.id,
        domname: !text ? '' : text.length > 64 ? text.substring(0, 64) : text,
        event,
        extra,
        x: parseInt('' + (pointerEvent.pageX || 0)),
        y: parseInt('' + (pointerEvent.pageY || 0)),
        href,
      }
      report(reportEvent)
      if (event) {
        track(event, extra)
      }
    },
    true
  )
}
