/**
 * @Author lizuping
 * @Date: 2023-06-27
 * @Last Modified by: lizuping
 *  该中间件只是为了做SEO 301向定向使用，一年后看到的有缘人请在nuxt.config.js 删除 serverMiddleware: ['@/middleware/blogUrl.ts']
 * @Last Modified time: 2023-07-03 13:12:23
 */
import { IncomingMessage, NextFunction } from 'connect'
import { ServerResponse } from 'http'
import axios from 'axios'
export default function (req: IncomingMessage, res: ServerResponse, next: NextFunction) {
  // 用老id换新id
  function getNewId(str: string, fuc: (id: string) => void) {
    axios.get(`http://oversea-server:8080/api/console/blog/portal/identifier/${str}`).then((r) => {
      fuc(r.data.identifier)
    })
  }
  const urlArr = (req.url || '').split('/')
  const ln = urlArr.length
  // blog id 是16进制数据，且刚好24位
  if (urlArr.length > 1 && urlArr[ln - 2] === 'blog' && /^[0-9a-f]{24}$/.test(urlArr[ln - 1])) {
    getNewId(urlArr[ln - 1], (str) => {
      urlArr[ln - 1] = str
      res.writeHead(301, { Location: urlArr.join('/') })
      res.end()
    })
  } else {
    next()
  }
}
