import { useContext, useStore, useRouter } from '@nuxtjs/composition-api'
import { getModule } from 'vuex-module-decorators'
import StoreModule, { UserInfo } from '@/store/store'
import { getJson } from '@/utils/api'
import { portalUrl } from '@/utils/constants'
export const initUser = () => {
  const store = getModule(StoreModule, useStore())
  const context = useContext()
  const router = useRouter()
  // const jtokenStr = context.$cookies.get(Jtoken)
  // if (jtokenStr) {
  //   try {
  //     const parts = jtokenStr.split('.')
  //     if (parts.length === 3) {
  //       const tokenUser = JSON.parse(atob(parts[1])) as TokenUser
  //       store.setUserInfo()
  //     }
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }

  return getJson<{ data: UserInfo; code: number; msg: string }>('/api/console/account/info/current')
    .then((res) => {
      if (res && res.data) {
        store.setUserInfo(res.data)
      } else {
        store.setUserInfo({} as UserInfo)
      }
      return res
    })
    .catch((err) => {
      console.warn(err)
      store.setUserInfo({} as UserInfo)
    })
}
