import report, { IExtra, ITrack, ReportEvent, ReportType } from './report'

export declare interface CustomReportEvent extends ReportEvent {
  event: string
}

export const track: ITrack = (event: string, extra?: IExtra | undefined | null) => {
  const customEvent: CustomReportEvent = {
    type: ReportType.Custom,
    event,
    extra,
  }
  report(customEvent)
}
