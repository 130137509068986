import { AxiosRequestConfig } from 'axios'
export default function ({ $axios, app }: any) {
  // 请求拦截器 多语言拦截
  $axios.onRequest((config: AxiosRequestConfig) => {
    config.headers.Locale = app.i18n.locale || 'en_US'
    return config
  })
  $axios.onRequestError((error: any) => {
    return Promise.reject(error)
  })
  $axios.onResponse((response: any) => {
    return response.data || response
  })
}
