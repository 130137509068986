import cssVars from 'css-vars-ponyfill';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { defaultTheme } from '../config/themeConfig';

export type ThemeState = typeof defaultTheme;

@Module({
  name: 'theme',
  stateFactory: true,
  namespaced: true,
})
export default class ThemeStoreModule extends VuexModule {
  theme = {...defaultTheme};
  isTheme: boolean = false;

  @Mutation
  updateTheme(payload: Partial<ThemeState>) {
    const nextTheme: { [x: string]: string } = {}
    Object.assign(this.theme, payload)
    Object.keys(this.theme).forEach((key) => {
      nextTheme[`--${key}`] = this.theme[key as keyof ThemeState]
    })

    cssVars({
      variables: nextTheme
    })
  }

  @Mutation
  setIsTheme(isTheme: boolean) {
    this.isTheme = isTheme;
  }
}