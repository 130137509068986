export type FooterItem = {
  title: string
  href?: string
  icon?: string
  sub?: { title: string; href: string; icon: string }[]
}

export const HEADERCONFIG: { headerInfo: FooterItem[] } = {
  headerInfo: [
    {
      title: 'header.home.name',
      href: '/',
    },
    {
      title: 'header.products.name',
      sub: [
        {
          title: 'header.products.sub[1]',
          href: '/app-push',
          icon: 'app-push',
        },
        {
          title: 'header.products.sub[2]',
          href: '/web-push',
          icon: 'web-push',
        },
        {
          title: 'header.products.sub[3]',
          href: '/sms',
          icon: 'sms',
        },
        {
          title: 'header.products.sub[4]',
          href: '/email',
          icon: 'email',
        },
        {
          title: 'header.products.sub[5]',
          href: '/whatsapp-business-api',
          icon: 'whatsapp',
        },
      ],
    },
    {
      title: 'header.blog.name',
      href: '/blog',
    },
    {
      title: 'header.docs.name',
      href: '/docs/app-push/product-overview',
    },
    {
      title: 'header.aboutUs.name',
      href: '/about',
    },
  ],
}
