import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import Cookie from 'cookie-universal'

import config from './config'
import { stringify } from '@/utils/format'

export const Etoken = 'Etoken'

const request = axios.create({
  // default options
  baseURL: config.baseURL,
})
// local、dev 时设置为 false，响应头 Access-Control-Allow-Origin: *
/* request.defaults.withCredentials = !(process.env.NUXT_ENV_RUNTIME === 'dev') */

const err = (error: AxiosError) => {
  const errorMsgObj = error.response?.data
  return Promise.reject(errorMsgObj)
}

request.interceptors.request.use((config) => {
  if (config.url?.startsWith('http')) {
    config.baseURL = ''
  }
  // TODO before request
  const cookies = Cookie()
  const locale = cookies.get('i18n_redirected') || 'en_US'
  const token = cookies.get(Etoken) || process.env.VUE_APP_TOKEN || ''
  config.headers.Locale = locale
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}, err)

request.interceptors.response.use((response) => response.data, err)

export const postJson = <ResData>(url: string, data: any, options?: AxiosRequestConfig) =>
  request.post<ResData, ResData>(url, data, options)

export const getJson = <ResData>(url: string, options: AxiosRequestConfig = {}) => request.get<ResData, ResData>(url, options)

export const putJson = <T>(url: string, data: any, options: AxiosRequestConfig = {}) => request.put<T, T>(url, data, options)

export const deteleJson = <T>(url: string, options: AxiosRequestConfig = {}) => request.delete<T, T>(url, options)

export const asyncPutJson = <T>(url: string, data: any, options: AxiosRequestConfig = {}): Promise<{ data?: T; err?: any }> =>
  request
    .put<T, T>(url, data, options)
    .then((res) => ({ data: res }))
    .catch((err) => ({ err }))

export const asyncPostJson = <Data>(url: string, data?: any, options?: AxiosRequestConfig): Promise<{ data?: Data; err?: any }> =>
  request
    .post<Data, Data>(url, data, options)
    .then((res) => ({ data: res }))
    .catch((err) => ({ err }))

export const asyncGetJson = <Data>(url: string, options?: AxiosRequestConfig): Promise<{ data?: Data; err?: any }> =>
  request
    .get<Data, Data>(url, options)
    .then((res) => ({ data: res }))
    .catch((err) => ({ err }))

export const asyncDeteleJson = <T>(url: string, options: AxiosRequestConfig = {}): Promise<{ data?: T; err?: any }> =>
  request
    .delete<T, T>(url, options)
    .then((res) => ({ data: res }))
    .catch((err) => ({ err }))

export const get = (url: string, data: any = {}, options: AxiosRequestConfig = {}) => {
  return getJson(url + stringify(data), options)
}
